




















































































































































































































































































































































































/* css provisional */
.login-container,
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.login-button {
  padding: 12px 50px !important;
  border-radius: 30px !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  background-color: #00add8;
  color: white;
  font-weight: bold;
  cursor: pointer;
  width: 70px;
  margin-bottom: 15px;
  text-align: center;
}

.login-button:active {
  background-color: #008fb3;
}

.tfa-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 25px;
  height: 100%;
}

.tfa-controls,
.tfa-input {
  display: flex;
  flex-direction: column;
}

input[type="text"],
input[type="password"] {
  background-color: none !important;
  border: none !important;
  border-bottom: 1px solid #242632 !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 10px 30px !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #242632 !important;
  margin-bottom: 40px;
}

.login-icon {
  font-size: 20px;
  position: absolute !important;
  padding-top: 10px !important;
  color: #242632;
}

.login-title {
  color: #0F0F1A;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15px;
  font-weight: 600;
  margin-bottom: 35px;
  font-size: 20px;
}

.login-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 35px;
  font-size: 14px;
}

.login-title,
.login-subtitle {
  margin: 0;
  margin-bottom: 35px;
}

.login-link {
  cursor: pointer;
  text-align: center;
  color: #00add8;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.login-link:hover {
  color: #00c1f2;
  text-decoration: underline;
}

.login-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 50px 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  width: max-content;
  margin-bottom: 54px;
  margin-top: 50px;
  padding: 75px 43px 62px;
  margin-bottom: 64px;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
}

.outer-link {
  text-align: center;
  color: #1F7AA0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 13px;
  text-decoration: none;
  cursor: pointer;
}

.outer-link:hover {
  color: #00c1f2;
}

.button-long {
  width: 150px;
}

.tfa-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.tfa-send-subcontainer {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-bottom: 10px;
}

.tfa-send-subcontainer span {
  font-size: 12px;
}

.button-disabled,
.button-disabled:hover {
  cursor: auto;
  background-color: #93c9d6 !important;
}
